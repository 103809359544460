import React, { FC } from 'react';
import styled from 'styled-components';
import { Colors } from '../styles/theme/colors';

const ApplicationContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;

const MainHeadline = styled.h1`
  color: ${Colors.secondary};
  font-size: 3rem;
  margin-bottom: 1rem;
`;
const SubHeadline = styled.h2`
  color: ${Colors.secondary};
  font-size: 2rem;
  margin-bottom: 1rem;
`;
const SubText = styled.p`
  color: ${Colors.secondary};
  font-size: 1rem;
  margin-bottom: 1rem;
`;

const SocialMediaContainer = styled.div`
  margin-top: 1px;
  border-top: 1px solid ${Colors.secondary};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Link = styled.a`
  font-size: 1rem;
  color: ${Colors.secondary};
  text-decoration: none;
  padding: 1rem 2rem;
  
  &:hover {
    color: ${Colors.primary};
  }
`;


const Application: FC = () => {
  return (
    <ApplicationContainer>
      <MainHeadline>
        Christian Müller
      </MainHeadline>
      <SubHeadline>
        Web Development
      </SubHeadline>
      <SubText>
        More coming soon...
      </SubText>
      <SocialMediaContainer>
        <Link href='https://github.com/cr0n3x' target='_blank'>
          <i className="fab fa-github"/>
        </Link>
        <Link href='https://twitter.com/cr0n3x' target='_blank'>
          <i className="fab fa-twitter"/>
        </Link>
        <Link href='https://instagram.com/cr0n3x' target='_blank'>
          <i className="fab fa-instagram"/>
        </Link>
        <Link href='mailto:hello@christianmueller.dev'>
          <i className="fal fa-envelope"/>
        </Link>
      </SocialMediaContainer>
    </ApplicationContainer>
  );
};

export default Application;
